<!-- <div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Upload File</h5>
        <button type="button" class="btn-close" (click)="modal.dismiss()"></button>

    </div>
    <div class="modal-body">
        <div class="mb-3">
            <label class="form-label">Attachment Name</label>
            <input [(ngModel)]="attachmentName" type="text" name="example-text-input" placeholder="Attachment Name"
                class="form-control">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
        <button type="button" class="btn btn-primary" (click)="submit()">Submit</button>

    </div>
</div> -->

<div class="modal-content">
    <div class="modal-body text-center py-4">

        <h3>Are you sure you want to proceed with the below file name?</h3>
        <input [(ngModel)]="attachmentName" type="text" name="example-text-input" placeholder="Attachment Name"
            class="form-control">
    </div>
    <div class="modal-footer">
        <div class="w-100">
            <div class="row">
                <div class="col"><a (click)="modal.dismiss()" class="btn w-100" data-bs-dismiss="modal">
                        Cancel
                    </a></div>
                <div class="col"><a (click)="submit()" class="btn btn-primary w-100" data-bs-dismiss="modal">
                        Confirm
                    </a></div>
            </div>
        </div>
    </div>
</div>