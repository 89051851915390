import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import { ApiResponse, Role } from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';
import { SessionConstants } from '../../../core/constants/session.constants';
import {
  LoginRequest,
  LogoutRequest,
  RegisterPayload,
  SwitchProfileRequest,
  UserEntity,
  UserSession,
} from '../models/auth.models';
import { Cloud, Sites } from '../models/property.models';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../../user/models/user.models';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseService {
  // private currentUserSubject = new BehaviorSubject<any | null>(null);

  constructor(private http?: HttpClient, private router?: Router) {
    super();
    // this.currentUserSubject.next(this.getCurrentUser());
  }

  authenticate(request: LoginRequest) {
    return this.http.post<ApiResponse<UserSession>>(
      `${this.gemURL}/auth/login`,
      request,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  logout(logoutRequest: LogoutRequest) {
    return this.http.post<ApiResponse<string>>(
      `${this.gemURL}/auth/logout`,
      {}
    );
  }

  forgotPassword(email: string) {
    return this.http.post<ApiResponse<any>>(
      `${this.gemURL}/user/password/forgot`,
      { userName: email },
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  resetPassword(userId, token, password) {
    return this.http.post<ApiResponse<any>>(
      `${this.gemURL}/user/password/${userId}/${token}`,
      { password: password },
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  register(payload: RegisterPayload) {
    return this.http.post<ApiResponse<string>>(
      `${this.gemURL}/register`,
      { userName: payload },
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  switchProfile(request: SwitchProfileRequest) {
    return this.http.patch<ApiResponse<UserSession>>(
      `${this.gemURL}/auth/session/switch/profile`,
      request,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }
  getEntity() {
    return this.http.get<ApiResponse<UserEntity>>(`${this.gemURL}/auth/entity`);
  }

  validateSession(payload: UserSession) {
    return this.http.post<ApiResponse<UserSession>>(
      `${this.gemURL}/auth/token`,
      payload,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  getUserEntity() {
    return JSON.parse(
      sessionStorage.getItem(
        sessionStorage.hasOwnProperty(SessionConstants.ENTITY)
          ? SessionConstants.ENTITY
          : null
      )
    );
  }

  isSessionActive() {
    if (this.getSession()) {
      return true;
    }
    return false;
  }

  getSession() {
    return JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.SESSION)
          ? SessionConstants.SESSION
          : SessionConstants.CALLBACK_SESSION
      )
    );
  }
  isCompanyUser(): boolean {
    return this.isRoleIn([Role.COMPANY_ADMIN, Role.COMPANY_USER])
  }
  isDepatmentIn(departments: string[]): boolean {
    var user = this.getCurrentUser();
    return departments.includes(user?.department)
  }
  isPositionIn(positions: string[]) {
    var user = this.getCurrentUser();
    return positions.includes(user?.position)
  }

  isRoleIn(roles: string[]): boolean {
    var user = this.getCurrentUser();
    return roles.includes(user?.role)
  }
  isRoleNotIn(roles: string[]): boolean {
    const user = this.getCurrentUser();
    return !roles.includes(user?.role);
  }

  setSession(session: UserSession) {
    localStorage.setItem(SessionConstants.SESSION, JSON.stringify(session));
  }

  getCurrentUser() {
    return JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.CURRENT_USER)
          ? SessionConstants.CURRENT_USER
          : SessionConstants.CALLBACK_CURRENT_USER
      )
    );
  }


  getCurrentUserRole() {
    return JSON.parse(
      localStorage.getItem(SessionConstants.SESSION)
    );
  }

  // getCurrentUserObservable(): Observable<any | null> {
  //   return this.currentUserSubject.asObservable();
  // }

  // setCurrentUser(session: any): void {
  //   localStorage.setItem(SessionConstants.CURRENT_USER, JSON.stringify(session));
  //   this.currentUserSubject.next(session); // Emit the new session to subscribers
  //   console.log("emmit===",this.getCurrentUser());
  // }

  getCurrentUserId() {
    let session = this.getSession();
    return session.adminForId ? session.adminForId : session.userId;
  }
  getCompanyId() {
    var user = this.getCurrentUser();
    return user.companyId;
  }
  getCurrentUserName() {
    let session = this.getSession();
    return session.name;
  }

  getEntityCompany() {
    return JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.ENTITY_COMPANY)
          ? SessionConstants.ENTITY_COMPANY
          : SessionConstants.CALLBACK_ENTITY_COMPANY
      )
    );
  }

  getEntityProfile() {
    return JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.ENTITY_PROFILE)
          ? SessionConstants.ENTITY_PROFILE
          : SessionConstants.CALLBACK_ENTITY_PROFILE
      )
    );
  }
  getChatSelected() {
    return JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.CHAT_SELECTED)
          ? SessionConstants.CHAT_SELECTED
          : SessionConstants.CALLBACK_CHAT_SELECTED
      )
    );
  }

  getSitesList(cloud?: Cloud) {
    const currentUserSession = this.getEntityCompany();
    var siteList = currentUserSession.data[0].sites;
    const session = this.getSession();
    if (session?.siteIds) {
      siteList = siteList.filter((s) => s._id == session.currentProfileId);
    }
    if (cloud) {
      siteList = siteList?.filter(s => s.clouds?.includes(cloud));
    }
    return siteList;
  }

  getFacilitiesForSite(siteId: string) {
    const currentUserSession = this.getEntityCompany();
    const siteList = currentUserSession.data[0]?.sites || [];
    const facilities = siteList.find((s) => s._id === siteId)?.facilities || [];
  
    if (!facilities.length) {
      return [];
    }
    const filteredFacilities = facilities.filter((facility) => facility.status === 'ENABLED');
    return filteredFacilities;
  }
  

  getFacilityList(siteId: string, cloud?: Cloud) {
    const currentUserSession = this.getEntityCompany();
    let facilityList = currentUserSession.data[0].sites[0].facilities;
    // const session = this.getSession();
    if (cloud) {
      facilityList = facilityList.filter((s) => facilityList.cloud == cloud);
    }
    return facilityList;
  }

  getPropertyById(siteId: string): Sites {
    return this.getSitesList()?.find((site) => site._id == siteId);
  }

  getCurrentActiveProfileId() {
    let session = this.getSession();
    let currentUser = this.getCurrentUser();
    let profileId;

    switch (currentUser.role) {
      case Role.PROPERTY_USER:
        if (session.currentProfileId) {
          profileId = session.currentProfileId;
        } else {
          profileId = session.siteIds ? session.siteIds[0] : undefined;
        }

        break;
      case Role.FACILITY_USER:
        if (session.currentProfileId) {
          profileId = session.currentProfileId;
        } else {
          profileId = session.siteIds ? session.siteIds[0] : undefined;
        }
        break;
    }
    return profileId;
  }
}
