import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-template',
  templateUrl: './error-template.component.html',
  styleUrls: ['./error-template.component.scss']
})
export class ErrorTemplateComponent implements OnInit{

  @Input() errorTitle:string;
  @Input() subTitle:string;
  @Input() hasErrorImage:boolean=true;
  @Input() showConfigureButton:boolean=false;

  constructor(private router : Router){

  }

  ngOnInit(): void {
   
  }

  goToReadings(){
    this.router.navigate(['log-management/readings'])
  }

}
