import { Component, EventEmitter, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Component({
  selector: 'app-debounce-search-input',
  templateUrl: './debounce-search-input.component.html',
  styleUrls: ['./debounce-search-input.component.scss'],
})
export class DebounceSearchInputComponent {
  @Output() search = new EventEmitter<string>();
  private searchSubject = new Subject<string>();
  constructor() {
    // Debounce and emit the search term
    this.searchSubject
      .pipe(
        debounceTime(300), // Delay for 300ms
        distinctUntilChanged() // Only emit if the value has changed
      )
      .subscribe((searchTerm) => {
        this.search.emit(searchTerm);
      });
  }

  onSearch(value: string): void {
    this.searchSubject.next(value); // Push the input value to the Subject
  }

  onInput(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }
}
