<div class="modal-content">
  <div class="modal-header">
    <div class="d-flex justify-content-between">
      <h4>{{title}}</h4>
    </div>
    <div>
      <button *ngIf="canDownload" class="btn" (click)="downloadAttachments()"> <i class="ti ti-download"></i></button>
      <button type="button" class="btn-close" (click)="close()"></button>
    </div>
  </div>
  <div class="modal-body">
    <div *ngIf="extension == 'pdf'">
      <pdf-viewer [src]="url" style="width: 100%; height: 80vh;"></pdf-viewer>
    </div>
    <div class="img-container" *ngIf="isImage(extension)">
      <div>
        <img [src]="url" alt="">
      </div>
    </div>

    <div class="multi-img-container" *ngIf="multiUrls?.length > 0">
      <ngb-carousel
        #carousel
        [pauseOnHover]="pauseOnHover"
        [pauseOnFocus]="pauseOnFocus"
        (slide)="onSlideChange($event)">
        <ng-template ngbSlide *ngFor="let url of multiUrls; let i = index" [id]="i.toString()">
          <div class="picsum-img-wrapper">
            <img [src]="baseUrl + url" alt="Image Slide" />
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
    

    <div *ngIf="isVideo(extension)">
      <video id="my_video_1" class="video-js vjs-default-skin" width="100%" height="100%" controls preload="auto"
        autoplay>
        <source [src]="url" type='video/webm' />
      </video>
    </div>
  </div>
</div>