<ng-container *ngIf="!guestId">
    <span class="d-flex align-items-center gap-1">
        <i class="ti ti-user fs-3 cursor-pointer " (click)="initiateGuestDetails()" [ngbPopover]="popOver"
            [autoClose]="'outside'" placement="left" triggers="click"></i>
    </span>
    
    <ng-template #popOver>
        <div style="width: 220px;">
            <div class="row">
                <div class="col-sm-12">
                    <div class="datagrid-item">
                        <div class="datagrid-title">Guest Name</div>
                        <div class="datagrid-content">{{guestName? guestName:'--'}}</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="datagrid-item">
                        <div class="datagrid-title">Check In</div>
                        <div class="datagrid-content">--</div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="datagrid-item">
                        <div class="datagrid-title">Check Out</div>
                        <div class="datagrid-content">--</div>
                    </div>
                </div>
            </div>
    
    
        </div>
    
    
    </ng-template>
</ng-container>

<ng-container *ngIf="guestId">
    <span class="d-flex align-items-center gap-1">
        <span class="avatar rounded-circle" *ngIf="guest?.shortName && guestId !== '-1' && userAvatar">{{guest?.shortName}}</span>
        <span class="cursor-pointer" *ngIf="guestId !== '-1'" [ngClass]="styleClasses" [disablePopover]="!showPopover" [ngbPopover]="initiator" container="body" triggers="mouseenter:mouseleave" [style.font-size]="fontSize" [style.color]="color">{{guest?.name.displayName?guest?.name.displayName:'--'}}</span>
        <span class="cursor-pointer" *ngIf="guestId == '-1'" [ngClass]="styleClasses"  [style.font-size]="fontSize" [style.color]="color">System</span>
    </span>
    
    <ng-template #initiator>
        <div class="popover-profile" sty>
            <div style="display: flex;flex-direction: column; align-items: center;">
                <ngx-avatar [name]="guest?.name?.displayName" size="30"
                    [textSizeRatio]="3" [initialsSize]="3"></ngx-avatar>
                <h4>{{guest?.name?.displayName}}</h4>
            </div>
            <hr />
            <div class="profile-body d-flex flex-column align-items-center justify-content-center">
                <div>
                    <span>
                        <i class="ti ti-phone fs-3"></i>
                    </span> {{guest?.primaryPhone.phoneNumber? (guest?.primaryPhone?.countryCode? '+'+guest?.primaryPhone?.countryCode : " ") + guest?.primaryPhone?.phoneNumber:" - -"}} 
                </div>
                <div *ngIf="guest.primaryEmail.emailId"> 
                    <span>
                        <i class="ti ti-mail fs-3"></i>
        
                    </span> 
                    {{guest.primaryEmail.emailId}}
                </div>
                
            </div>
        </div>
    </ng-template>
</ng-container>
