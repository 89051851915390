import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/core/services/base.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getImage(path: string) {
    return this.imgURL + 'api/instio/images/' + path;
  }

  uploadAttachments(
    form: FormData,
    domainKey?: string,
    keepOriginalName?: boolean
  ) {
    keepOriginalName = keepOriginalName || false;
    let params = new HttpParams().set('keepOriginalName', keepOriginalName);
    // Determine the domain based on domainKey or default to logBook
    const domainMap: { [key: string]: string } = {
      gem: this.gemURL,
      oldSite: this.oldSiteURL,
      img: this.imgURL,
      workOrder: this.workorderUrl,
      wo: this.woURL,
      review: this.reviewURL,
      googleSignIn: this.googleSignInURL,
      crm: this.crmURL,
      communication: this.communicationURL,
      report: this.reportURL,
      budget: this.budgetUrl,
    };

    const domain = domainKey
      ? domainMap[domainKey] || this.logBook
      : this.logBook;

    return this.http.post<any>(`${domain}/general/upload`, form, {
      params: params,
    });
  }
}
