import {
  HttpClient,
  HttpContext,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { List } from 'lodash';
import { SessionConstants } from 'src/app/core/constants/session.constants';
import {
  ApiListHouseKeeping,
  ApiListResponse,
  ApiPaginationResponse,
  ApiResponse,
  ApiRoomSummery,
  BaseFilter,
  HKPaginationResponse,
  HKResponse,
} from 'src/app/core/models/common.models';
import { BaseService } from 'src/app/core/services/base.service';
import {
  CleaningProfile,
  CleaningProfileFilter,
  CreateCleaningProfileRequest,
} from '../models/cleaning_profile.models';
import {
  commonReq,
  Departments,
  WorkersStats,
  WorkStatsSearch,
} from '../models/day.models';
import {
  RoomStatusForAssignment,
  roomInfosForAssignmentFilter,
  UserInfo,
  WorkerStatsAssignedForAllocations,
  WorkerStatsAssignedForAllocationsFilter,
  WorkScheduleBulkRequest,
  RoomInfoStatus,
  RoomType,
  Sector,
  scheduleAllocationRequest,
  WorkerStatusSummeryResponse,
  TaskUpdateRequest,
  RaReportRequest,
  RaReport,
  WorkReportRequest,
  forAssignmentRequest,
  RaRoomReportStatus,
  WorkFlowFilter,
  UpdateCreditRequest,
  UpdateCreditResponse,
  ActivityResponse,
  TimeLogResponse,
  WorkerStatusSummeryRequest,
  WorkerWorks,
  RoomStatusReport,
  CancelLogReq,
  RoomStatusRq,
  RevertReq,
  UserFeedbackPayload,
} from '../models/scheduler.models';
import {
  RoomStatusFilter,
  RoomStatusInfo,
  RoomStatusUpdate,
} from '../models/room_status.models';
import {
  CleaningHistoryRequest,
  CleaningHistoryResponse,
  GuestDetailsRequest,
  GuestDetailsResponse,
  Reservation,
  RoomStatusInfoSummeryReques,
  SpecialRequestResponse,
} from '../models/status_report.moodels';
import {
  CreateRoomRequest,
  CreateRoomResponse,
  FilterSectorRequest,
  FilterSectorResponse,
  SearchRoomRequest,
  SectorRequest,
  SectorResponse,
} from '../models/sectors.model';
import {
  CreateRoomTypeRequest,
  GetRoomTypesRequest,
  RoomTypes,
} from '../models/room_type.models';
import {
  CleaningPreference,
  CleaningPreferenceRequest,
  LastAssigned,
  TaskInfo,
  workFlowResponse,
} from '../models/cleaning-preference.model';
import {
  AllocationSraturgySearch,
  AllocationStrategyCreate,
} from '../models/assigned-rules';
import {
  GuestSearchRequest,
  GuestHousekeepingSearch,
} from '../models/guest.models';
import { User } from '../../user/models/user.models';
import { BYPASS_INJECTION } from 'src/app/core/interceptors/common.interceptor';
import {
  ConfigurationResponse,
  CreateConfigurationRequest,
} from '../models/config.models';

@Injectable({
  providedIn: 'root',
})
export class HouseKeepService extends BaseService {
  tempApi = 'http://192.168.0.4:9400';

  constructor(private http: HttpClient) {
    super();
  }

  getUserInfo(payload: BaseFilter) {
    return this.http.post<ApiPaginationResponse<User[]>>(
      this.oldSiteURL + `/api/user/list`,
      payload
    );
  }

  create(req: WorkersStats) {
    // console.log(req, 'rrreeq');

    return this.http.post<HKResponse<WorkersStats>>(
      this.hkUrl + `/workers_stats`,
      req
    );
    // return this.http.post(this.tempApi+`/workers_stats`,req)
  }

  search(request: WorkStatsSearch) {
    return this.http.post<HKPaginationResponse<WorkersStats[]>>(
      this.hkUrl + `/workers_stats/search`,
      request
    );
  }

  listWorkers(params: HttpParams) {
    return this.http.get<HKResponse<WorkersStats[]>>(
      `${this.hkUrl}/workers_stats`,
      { params: params }
    );
  }

  getWorkerStats(request: WorkStatsSearch) {
    return this.http.post<HKPaginationResponse<WorkersStats[]>>(
      this.hkUrl + `/workers_stats/search`,
      request
    );
  }

  create_bulk(req: WorkersStats[]) {
    return this.http.post(this.hkUrl + `/workers_stats/bulk`, req);
  }

  getDepartments(request: commonReq) {
    return this.http.post<ApiResponse<Departments[]>>(
      `${this.oldSiteURL}/api/department/dropdown`,
      request
    );
  }

  create_WorkSchedule_bulk(request: WorkersStats[]) {
    return this.http.post(this.hkUrl + `/workers_schedule/bulk`, request);
  }

  get_roomSummery(payload: RoomStatusInfoSummeryReques) {
    return this.http.post<HKResponse<Reservation>>(
      this.hkUrl + `/room_status_info/summary`,
      payload
    );
  }

  async userInformation(staffId: String) {
    var staffInfo: UserInfo = JSON.parse(
      localStorage.getItem('instio.v1.popovers.user.' + staffId)
    );

    if (staffInfo != null) return staffInfo;
    else {
      var user = await this.http
        .get<ApiResponse<UserInfo>>(
          this.oldSiteURL + `/api/user/info/public?userId=` + staffId
        )
        .toPromise();
      if (user)
        localStorage.setItem(
          'instio.v1.popovers.user.' + staffId,
          JSON.stringify(user['data'])
        );
      staffInfo = JSON.parse(
        localStorage.getItem('instio.v1.popovers.user.' + staffId)
      );
      return staffInfo;
    }
  }

  roomInfosForAssignment(payload: roomInfosForAssignmentFilter) {
    return this.http.post<HKResponse<RoomStatusForAssignment[]>>(
      this.hkUrl + `/room_status_info/for-assignment`,
      payload
    );
  }

  workerStatsAssignedForAllocations(payload: any, statId) {
    let params = new HttpParams();
    params = params.set('companyId', payload.companyId);
    params = params.set('statsFor', payload.statsFor);
    params = params.set('workerShift', payload.workerShift);
    params = params.set('staffId', payload.staffId);
    if (payload.siteId) {
      params = params.set('siteId', payload.siteId);
    }

    return this.http.get<HKResponse<WorkerStatsAssignedForAllocations[]>>(
      this.hkUrl + `/workers_stats/${statId}/assigned-for-allocations`,
      { params: params }
    );
  }

  updateRoomStatuses(payload: RoomStatusUpdate, id: number) {
    // console.log(payload, 'payload');
    return this.http.patch<HKResponse<RoomStatusInfo>>(
      this.hkUrl + `/room_status_info/${id}/status`,
      payload
    );
  }

  getCleaningProfiles(payload: CleaningProfileFilter) {
    return this.http.post<HKPaginationResponse<CleaningProfile[]>>(
      this.hkUrl + `/cleaning_profile/search`,
      payload
    );
  }
  updateCleaningProfile(payload: CleaningProfile) {
    return this.http.put<HKResponse<CleaningProfile>>(
      this.hkUrl + `/cleaning_profile/${payload.id}`,
      payload
    );
  }
  getRoomStatuses(payload: any) {
    //console.log(payload,'payload')
    // payload.page = payload.page== -1? 0 : payload.page - 1
    return this.http.post<HKPaginationResponse<RoomStatusInfo[]>>(
      this.hkUrl + `/room_status_info/search`,
      payload
    );
  }

  workSheduleBulk(payload: WorkScheduleBulkRequest[]) {
    return this.http.post<HKResponse<String>>(
      this.hkUrl + `/workers_schedule/bulk`,
      payload
    );
  }

  // roomInfoStatus(payload: any) {
  //   return this.http.post<HKResponse<RoomInfoStatus>>(
  //     `https://demo.pms.instio.co/api/pms/v2/room_info/stats`,
  //     payload
  //   );
  // }

  getRooms(request: commonReq) {
    return this.http.post<HKPaginationResponse<RoomType[]>>(
      this.hkUrl + `/rooms/dropdown`,
      request
    );
  }

  // sector services
  createSector(payload: SectorRequest) {
    return this.http.post<HKResponse<SectorResponse>>(
      this.hkUrl + '/sector',
      payload
    );
  }

  updateSector(payload: SectorRequest, id: Number) {
    return this.http.put<HKResponse<SectorResponse>>(
      this.hkUrl + `/sector/${id}`,
      payload
    );
  }

  getSector(id: number) {
    return this.http.get<HKResponse<SectorResponse>>(
      this.hkUrl + `/sector/${id}`
    );
  }

  deleteSector(id: number) {
    return this.http.delete<HKResponse<SectorResponse>>(
      this.hkUrl + `/sector/${id}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  searchSector(payload: FilterSectorRequest) {
    return this.http.post<HKPaginationResponse<FilterSectorResponse[]>>(
      this.hkUrl + '/sector/search',
      payload
    );
  }

  createRoom(payload: CreateRoomRequest) {
    return this.http.post<HKResponse<CreateRoomResponse>>(
      this.hkUrl + '/rooms',
      payload
    );
  }

  getRoomsForSector(
    sectorId: number,
    companyId: string,
    siteId?: string,
    roomTypeId?: number
  ) {
    let params = new HttpParams();
    params = params.set('companyId', companyId);
    if (siteId) {
      params = params.set('siteId', siteId);
    }
    if (roomTypeId) {
      params = params.set('roomTypeId', roomTypeId);
    }
    params = params.set('sectorId', sectorId);
    return this.http.get<HKResponse<CreateRoomResponse[]>>(
      this.hkUrl + `/rooms`,
      { params: params }
    );
  }

  updateRoom(payload: CreateRoomRequest, id: number) {
    return this.http.put<HKResponse<CreateRoomResponse>>(
      this.hkUrl + `/rooms/${id}`,
      payload
    );
  }

  deleteRoom(id) {
    return this.http.delete<HKResponse<CreateRoomResponse>>(
      this.hkUrl + `/rooms/${id}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  getRoom(id: number) {
    return this.http.get<HKResponse<CreateRoomResponse>>(
      this.hkUrl + `/rooms/${id}`
    );
  }

  searchRoom(payload: SearchRoomRequest) {
    return this.http.post<HKPaginationResponse<CreateRoomResponse[]>>(
      this.hkUrl + '/rooms/search',
      payload
    );
  }

  //

  create_roomType(payload: CreateRoomTypeRequest) {
    return this.http.post<HKResponse<CreateRoomTypeRequest>>(
      this.hkUrl + `/room_type`,
      payload
    );
  }

  get_roomType(payload: GetRoomTypesRequest) {
    return this.http.post<HKPaginationResponse<CreateRoomTypeRequest[]>>(
      this.hkUrl + `/room_type/search`,
      payload
    );
  }

  update_roomType(payload: CreateRoomTypeRequest, id: Number) {
    return this.http.put<HKResponse<RoomTypes>>(
      this.hkUrl + `/room_type/${id}`,
      payload
    );
  }

  delete_roomType(id: Number) {
    return this.http.delete<HKResponse<RoomTypes>>(
      this.hkUrl + `/room_type/${id}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) })
  }

  getRoomTypeById(id: Number) {
    return this.http.get<HKResponse<CreateRoomTypeRequest>>(
      this.hkUrl + `/room_type/${id}`
    );
  }

  //'https://demo.pms.instio.co/api/pms/v2/room_info/stats'

  // /**
  //  * @deprecated The method should not be used
  //  */
  DefaultAllocationUser(roomId: string, staffId: string) {
    const params = new HttpParams().set('assignedId', staffId);
    return this.http.get(
      this.hkUrl + `/room_status_info/${roomId}/default-assignment`,
      { params: params }
    );
    // "",{ context: new HttpContext().set(BYPASS_INJECTION, true),params:params })
  }

  autoAssign(payload: commonReq) {
    return this.http.post<HKResponse<String>>(
      this.hkUrl + '/workers_schedule/auto-assign',
      payload
    );
  }

  // cleaning preferences
  createCleaningPreference(payload: CleaningPreferenceRequest) {
    return this.http.post<HKResponse<CleaningPreference>>(
      this.hkUrl + `/room_type_cleaning_profile`,
      payload
    );
  }

  getCleaningPreferences(id: Number) {
    return this.http.get<HKResponse<CleaningPreference>>(
      this.hkUrl + `/room_type_cleaning_profile/${id}`
    );
  }

  updateCleaningPreference(payload: CleaningPreferenceRequest, id: Number) {
    return this.http.put<HKResponse<CleaningPreference>>(
      this.hkUrl + `/room_type_cleaning_profile/${id}`,
      payload
    );
  }

  allocation_strategy_search(payload: commonReq) {
    return this.http.post<HKResponse<AllocationSraturgySearch>>(
      this.hkUrl + '/allocation_strategy/search',
      payload
    );
  }
  allocation_strategy_create(payload: AllocationStrategyCreate) {
    return this.http.post<HKResponse<AllocationSraturgySearch>>(
      this.hkUrl + '/allocation_strategy',
      payload
    );
  }

  roomsDropDown(payload) {
    return this.http.post<HKResponse<RoomType[]>>(
      this.hkUrl + '/rooms/dropdown',
      payload
    );
  }

  sectorsDropDown(payload) {
    return this.http.post<HKResponse<Sector[]>>(
      this.hkUrl + '/sector/dropdown',
      payload
    );
  }

  workerStatus_Summery(payload) {
    return this.http.get<HKResponse<WorkerStatusSummeryResponse>>(
      this.hkUrl + `/workers-metrics/${payload.id}/overview`
    );
    // /workers_stats/summary
  }

  saveAssignment(payload: scheduleAllocationRequest) {
    return this.http.put<{ code: number; data: string; message: string }>(
      this.hkUrl + '/workers_schedule',
      payload
    );
  }

  markAllasDirty(payload: RoomStatusUpdate) {
    return this.http.patch<HKResponse<String>>(
      this.hkUrl + `/room_status_info/status`,
      payload
    );
  }

  updateTask(payload: TaskUpdateRequest, id: Number) {
    return this.http.put<HKResponse<CleaningPreference>>(
      this.hkUrl + `/tasks/${id}`,
      payload
    );
  }

  raReport(companyId: string, payload: RaReportRequest) {
    return this.http.post<ApiResponse<RaReport[]>>(
      this.reportV2URL + `report/v2/${companyId}/housekeeping/ra_summary/info`,
      payload
    );
  }

  getTask(id: number, collapse: boolean) {
    const params = new HttpParams().set('collapse', collapse);
    return this.http.get<HKResponse<TaskInfo>>(
      this.hkUrl + `/tasks/${id}`,
      { params: params }
    );
  }

  getRoomStatus(id: number) {
    return this.http.get<HKResponse<string>>(
      this.hkUrl + `/room_status_info/${id}/current_cleaning_profile`
    );
  }

  workReport(companyId: String, payload: WorkReportRequest) {
    return this.http.post(
      this.reportV2URL +
        `report/v2` +
        `/export/${companyId}/housekeeping/assignment/info`,
      payload,
      { responseType: 'blob' }
    );
  }

  forAddAssignment(payload: forAssignmentRequest) {
    return this.http.post<{ code: number; data: string; message: string }>(
      this.hkUrl + '/workers_schedule',
      payload
    );
  }

  raReportPrint(companyId: string, payload: RaReportRequest) {
    return this.http.post(
      this.reportV2URL +
        `report/v2/export/${companyId}/housekeeping/ra_summary/info`,
      payload,
      { responseType: 'blob' }
    );
  }

  getRoomStatusInfo(companyId: String, taskId: Number) {
    return this.http.get<ApiListHouseKeeping<RaRoomReportStatus>>(
      this.reportV2URL + `report/v2/${companyId}/${taskId}/status/info`
    );
  }

  getLastAssignedTime(payload) {
    return this.http.post<HKResponse<LastAssigned[]>>(
      this.hkUrl + `/workers_schedule/log-entry`,
      payload
    );
  }

  getWorkFlow(payload: WorkFlowFilter) {
    return this.http.post<HKResponse<workFlowResponse[]>>(
      this.hkUrl + '/workflows/search',
      payload
    );
  }

  getGuestDetails(payload: GuestDetailsRequest) {
    return this.http.post<ApiResponse<GuestDetailsResponse>>(
      this.pmsURL + '/internal/guest_info',
      payload
    );
  }

  getCleaningHistory(companyId: String, payload: CleaningHistoryRequest) {
    return this.http.post<ApiResponse<CleaningHistoryResponse[]>>(
      this.reportV2URL + `report/v2` + `/${companyId}/rooms/cleaning/histories`,
      payload
    );
  }

  getSpecialRequest(payload: CleaningHistoryRequest) {
    return this.http.post<HKResponse<SpecialRequestResponse[]>>(
      this.hkUrl + `/guest_housekeeping/list`,
      payload
    );
  }

  getGuestHouseKeepingSearch(payload: GuestSearchRequest) {
    return this.http.post<HKPaginationResponse<GuestHousekeepingSearch[]>>(
      this.hkUrl + `/guest_housekeeping/search`,
      payload
    );
  }

  updateCredits(payload: UpdateCreditRequest, taskID: Number, id: Number) {
    return this.http.put<HKResponse<UpdateCreditResponse>>(
      this.hkUrl + `/workflows/${taskID}/workflow/${id}`,
      payload
    );
  }

  getActivityInfo(taskId: Number) {
    return this.http.get<HKResponse<ActivityResponse[]>>(
      this.hkUrl + `/task/activity/${taskId}`
    );
  }

  getTimeLogs(taskId: any, workFlowId: any) {
    const params = new HttpParams().set('workFlowId', workFlowId);
    return this.http.get<HKResponse<TimeLogResponse[]>>(
      this.hkUrl + `/task_time_log/${taskId}`,
      { params: params }
    );
  }
  getWorkerWorks(payload: WorkerStatusSummeryRequest, companyId: string) {
    return this.http.post<ApiResponse<WorkerWorks>>(
      this.reportV2URL +
        `report/v2/${companyId}/housekeeping/ra_worker_works/info`,
      payload
    );
  }

  guestRequests(roomId, date) {
    return this.http.get<any>(
      this.hkUrl + `/guest_housekeeping/request?roomId=${roomId}&date=${date}`
    );
  }

  //HK room status report
  _HkRoomStatusReport(companyId: string, payload: RoomStatusRq) {
    return this.http.post<ApiResponse<RoomStatusReport[]>>(
      this.reportV2URL + `report/v2/${companyId}/housekeeping/room_stats/info`,
      payload
    );
  }

  // to cancel time log
  // https://demo.hk.instio.co/task_time_log/{taskId}
  _CancelTimeLog(taskId: number, payload: CancelLogReq) {
    return this.http.post<ApiResponse<any>>(
      this.hkUrl + `/task_time_log/${taskId}`,
      payload
    );
  }

  //to revert ṭask
  _RevertTask(taskId: number, payload: RevertReq) {
    return this.http.put<HKResponse<any>>(
      this.hkUrl + `/tasks/${taskId}`,
      payload
    );
  }

  createCleaningProfiles(payload: CreateCleaningProfileRequest) {
    return this.http.post<HKPaginationResponse<CleaningProfile[]>>(
      this.hkUrl + `/cleaning_profile`,
      payload
    );
  }

  deleteCleaningProfiles(id) {
    return this.http.delete<HKPaginationResponse<any>>(
      this.hkUrl + `/cleaning_profile/${id}`,
      { context: new HttpContext().set(BYPASS_INJECTION, true) }
    );
  }

  configService(payload: CreateConfigurationRequest) {
    return this.http.post<HKPaginationResponse<any>>(
      this.hkUrl + `/cleaning-service`,
      payload
    );
  }

  getConfigService(companyId, siteId) {
    // const params = new HttpParams()
    //   .set('companyId', companyId)
    //   siteId ? params.set('siteId', siteId) : null
    let params = new HttpParams();
    params = params.set('companyId', companyId);
    if (siteId) {
      params = params.set('siteId', siteId);
    }

    return this.http.get<HKResponse<ConfigurationResponse[]>>(
      this.hkUrl + `/cleaning-service`,
      { params: params }
    );
  }

  sentUserFeedback(payload : UserFeedbackPayload){
    return this.http.post<HKResponse<any>>(
      this.n8nUrl + `webhook/hk-user-feedback`,
      payload
    );
  }
}
