import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CrmService } from 'src/app/modules/guest-crm/crm.service';
import { CRMProfile } from 'src/app/modules/guest-crm/models/crm.models';
import { RoomStatusInfo } from 'src/app/modules/housekeeping/models/room_status.models';
import { GuestDetailsRequest } from 'src/app/modules/housekeeping/models/status_report.moodels';
import { HouseKeepService } from 'src/app/modules/housekeeping/services/house-keep.service';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss'],
})
export class GuestInfoComponent implements OnInit,OnChanges {
  @Input() fontSize: string;
  @Input() color: string;
  @Input() showPopover: boolean = true;
  @Input() styleClasses: string[];
  @Input() guestIcon: boolean = false;
  @Input() room: string;
  @Input() siteId: string;
  @Input() guestId : string;
  @Input() userAvatar: boolean = false;
  guestInfo: any;
  guestName: string;
  public guest : CRMProfile
  
  constructor(private houseKeepingService: HouseKeepService,
    private crmService : CrmService
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['guestId'] && changes['guestId'].currentValue !== changes['guestId'].previousValue) {
      this.getGuestInfo();
    }
  }

  ngOnInit(): void {}

  initiateGuestDetails() {
    const payload = new GuestDetailsRequest();
    payload.status = ['checkIn'];
    payload.room = this.room;
    payload.siteId = this.siteId;
    this.guestInfo = null;
    this.houseKeepingService.getGuestDetails(payload).subscribe({
      next: (res) => {
        this.guestInfo = res.data;
        this.guestName =
          this.guestInfo?.firstName + ' ' + this.guestInfo?.lastName;
      },
      error: (err) => {},
    });
  }

  getGuestInfo(){
    if(this.guestId !== '-1' && this.guestId){
      this.crmService.getGuestProfile(this.guestId).subscribe({
        next:(res)=>{
          console.log(res);
          this.guest = res.data;
          this.guest.shortName = this.guest?.name?.firstName[0] + (this.guest?.name?.lastName ? this.guest?.name?.lastName[0] : '');
        },
        error:(err)=>{
          console.log(err);
          
        }
      })
    }
    
  }
}
