import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { SessionConstants } from '../constants/session.constants';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService {
  public gemURL: string = environment.gemURL;
  public oldSiteURL: string = environment.oldSiteURL;
  public imgURL: string = environment.imgURL;
  public workorderUrl: string = environment.workorderUrl;
  public woURL: string = environment.woUrl;
  public reviewURL: string = environment.reviewURL;
  public googleSignInURL: string = environment.googleSignInURL;
  public crmURL: string = environment.crmURl;
  public communicationURL: string = environment.communicationURL;
  public reportURL: string = environment.reportURL;
  public reportV2URL: string = environment.reportV2URL;
  public hkUrl: string = environment.hkUrl;
  public docUrl: string = environment.docUrl;
  public unLayerUrl: string = environment.unLayerUrl;
  public pmsURL: string = environment.pmsURL;
  public pmsURL2: string = environment.pmsURL2;
  public segmentsURL: string = environment.segmentsURL;
  public loyaltyUrl: string = environment.loyaltyUrl;
  public taskUrl: string = environment.taskUrl;
  public logBook: string = environment.logBook;
  public auditUrl: string = environment.auditUrl;
  public budgetUrl: string = environment.budgetUrl;
  public marketingUrl: string = environment.marketingURL;
  public qcUrl: string = environment.qcUrl;
  public n8nUrl: string = environment.n8nURL;

  public eventUrl: string = environment.eventURL;
  public ppmUrl: string = environment.ppmURL;
  // public cixURL: string = environment.cixURL;
  public cixUrl: string = environment.cixURL;
  public rosterUrl: string = environment.rosterURL;

  constructor() {}

  protected setInitiatedById(request: any) {
    const currentUser = JSON.parse(
      localStorage.getItem(
        localStorage.hasOwnProperty(SessionConstants.CURRENT_USER)
          ? SessionConstants.CURRENT_USER
          : SessionConstants.CALLBACK_CURRENT_USER
      )
    );
    request.initiatedById = currentUser.id;
  }
}
