import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GetEntityParams, PropertyService } from 'src/app/core/services/property.service';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { Properties } from '../../models/notification.models';
import { Cloud } from 'src/app/modules/auth/models/property.models';

@Component({
  selector: 'app-property-dropdown',
  templateUrl: './property-dropdown.component.html',
  styleUrls: ['./property-dropdown.component.scss'],
})
export class PropertyDropdownComponent implements OnInit, OnChanges {
  @Output() propertySelected = new EventEmitter<string>();
  @Input() allRequired: boolean
  @Input() width: any
  @Input() cloud: Cloud;
  @Input() siteId: string
  public properties: Properties[];
  @Input() selectedProperty: string;

  constructor(private authService: AuthService,
    private propertyService: PropertyService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteId']) {
      this.selectedProperty = this.siteId
    }
  }

  ngOnInit(): void {
    this.getProperties();
    if (this.siteId) {
      this.selectedProperty = this.siteId
    }
    
  }

  getProperties() {
    if (this.cloud) {
      this.properties = this.authService.getSitesList(this.cloud);
    } else {
      this.properties = this.authService.getSitesList();
    }
  }

  changeProperty() {
    this.propertySelected.emit(this.selectedProperty)
  }
}
