<div class="empty" >
    <div class="empty-img" *ngIf="hasErrorImage"><img src="assets/images/error/no_data.svg" height="128" alt="">
    </div>
    <p class="empty-title">{{errorTitle}}</p>
    <p class="empty-subtitle text-muted">
     {{subTitle}}
    </p>
    <p *ngIf="showConfigureButton">
      <button class="btn btn-primary" (click)="goToReadings()">
        <i class="ti ti-settings fs-2"></i>
        <span class="ms-1">
            Configure
        </span>
      </button>
    </p>
  </div>
