import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageService } from '../../service/image.service';

@Component({
  selector: 'app-common-file-upload',
  templateUrl: './common-file-upload.component.html',
  styleUrls: ['./common-file-upload.component.scss'],
})
export class CommonFileUploadComponent implements OnInit {
  attachmentName: string;
  @Input() attachment: File;
  componentInstance: File[];
  extension: string;
  constructor(
    public modal: NgbActiveModal,
    private imageService: ImageService
  ) {}

  ngOnInit(): void {
    this.processOtherFile(this.attachment);
  }

  fileSelected(event) {}

  getImage() {}

  processOtherFile(file: File) {
    console.log(file);
    this.attachment = file;
    const extensionMatch = file.name.match(/\.([^\.]+)$/); // Matches the last . and any characters after it
    const extension = extensionMatch ? extensionMatch[1] : ''; // Extract extension if found
    const baseName = file.name.replace(/\.[^\.]+$/, '');
    this.attachmentName = baseName;
    this.extension = extension;
  }

  getAttachment() {
    return [];
  }

  submit() {
    if (this.attachment) {
      const fullFilePath = `${this.attachmentName}.${this.extension}`;
      const renamedFile = new File([this.attachment], fullFilePath, {
        type: this.attachment.type,
      });
      this.modal.close(renamedFile);
    }
  }
}
