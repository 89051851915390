<div [ngClass]="styleClasses">
  <span class=" d-inline-flex align-items-center lh-1" [ngClass]="getColorClass()">
    <span *ngIf="type=='AMOUNT'">{{(percentValue!=null && percentValue!=undefined) ? (percentValue | currency:'INR') :
      '--' }}</span>
    <span *ngIf="type=='NUMBER'">{{(percentValue!=null && percentValue!=undefined) ? percentValue : '--' }}</span>
    <span *ngIf="type=='PERCENTAGE'">{{(percentValue!=null&& percentValue!=undefined) ? percentValue : '--' }}%
    </span>

    <i class="ti ti-trending-up" *ngIf="percentValue>0 && showPercentageColor"></i>
    <i class="ti ti-trending-down" *ngIf="percentValue<0 && showPercentageColor"></i>
  </span>
</div>