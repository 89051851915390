import { Component, OnInit } from '@angular/core';
import { AuthService } from './modules/auth/services/auth.service';
import { SessionConstants } from './core/constants/session.constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private authService:AuthService){}
  title = 'instiogem-newui-v3.5';
  ngOnInit(): void {
    let session=this.authService.getSession();
    let entity=this.authService.getUserEntity()
    if(!entity && session)this.getEntity();
  }
  getEntity(){
    this.authService.getEntity().subscribe({
      next:(response)=>{
        sessionStorage.setItem(SessionConstants.ENTITY,JSON.stringify(response.data))
      },
      error:(error)=>{}
    })
  }
  
}
