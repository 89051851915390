
import { RaRoomReportStatus } from 'src/app/modules/housekeeping/models/scheduler.models';
import { DateRange } from 'src/app/modules/log-management/models/department-log.models';

export interface ApiResponse<T> {
  status: boolean;
  data: T;
  statusCode: number;
  errMessage?: string;
}
export interface HKResponse<T> {
  code: number;
  message: String;
  data: T;
}
export interface HKPaginationResponse<T> {
  code: number;
  message: string;
  data: HKPaginatedWebData<T>;
}
export interface ApiRoomSummery<T> {
  code: number;
  message: String;
  data: T;
}
export interface ApiResponseTwo<T> {
  status: string;
  data: T;
  code: number;
}
export interface ApiPaginationResponse<T> {
  status: boolean;
  statusCode: number;
  data: PaginatedWebData<T>;
}

export class ApiPaginatedResponse<T> {
  public data: PaginatedWebData<T>;
  public message: string;
  public code: number;
}
export class HkPaginatedResponse<T> {
  public data: HKPaginatedWebData<T>;
  public message: string;
  public code: number;
}

export class PaginatedWebData<T> {
  public data: T;
  public list: T;
  public currentPage: number;
  public rowsPerPage: number;
  public totalPages: number;
  public totalRecords: number;
  public totalRowCount: number;
  public recordsPerPage: number;
}

export class whatsappPaginatedWebData<T> {
  public data: T;
  public list: T;
  public currentPage: number;
  public rowsPerPage: number;
  public totalPages: number;
  public totalRecords: number;
  public totalRowCount: number;
  public recordsPerPage: number;
}

export class HKPaginatedWebData<T> {
  list: T;
  recordsPerPage: number;
  totalRecords: number;
  page: number;
  totalPages: number;
}

export class PaginatedDataResponse<T> {
  status: string;
  data: HKPaginatedWebData<T>;
  code: number;
}

export class DateRangeSelected {
  startTime: number;
  endTime: number;
  range: Ranges;
}

export class HkResponse<T> {
  data: T;
  message: string;
  code: number;
}
export interface ApiListResponse<T> {
  status: boolean;
  statusCode: number;
  data: PaginatedListData<T>;
}
export class PaginatedListData<T> {
  list: T;
  recordsPerPage: number;
  totalRecords: number;
  page: number;
  totalPages: number;
}

export enum RangeCategory {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  CUSTOM = 'CUSTOM',
  OVERALL = 'OVERALL',
}

export enum Ranges {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  CUSTOM = 'CUSTOM',
  OVERALL = 'OVERALL',
}

export const RANGE_CAT_LIST = [
  { interval: Ranges.TODAY, category: RangeCategory.DAY },
  { interval: Ranges.YESTERDAY, category: RangeCategory.DAY },
  { interval: Ranges.LAST_7_DAYS, category: RangeCategory.WEEK },
  { interval: Ranges.LAST_30_DAYS, category: RangeCategory.MONTH },
  { interval: Ranges.THIS_MONTH, category: RangeCategory.MONTH },
  { interval: Ranges.LAST_MONTH, category: RangeCategory.MONTH },
  { interval: Ranges.OVERALL, category: RangeCategory.YEAR },
  { interval: Ranges.CUSTOM, category: RangeCategory.CUSTOM },
];

export enum Source {
  WEB = 'WEB',
  MOBILE = 'MOBILE',
}

export enum Role {
  INSTIO_ADMIN = 'INSTIO_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  COMPANY_USER = 'COMPANY_USER',
  PROPERTY_USER = 'PROPERTY_USER',
  FACILITY_USER = 'FACILITY_USER',
}

export enum Status {
  ACTIVE = 'ENABLED',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum CommonStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
}

export interface ApiListHouseKeeping<T> {
  status: boolean;
  statusCode: number;
  data: RaRoomReportStatus;
}
export class CommonRequest {
  companyId: string;
  siteId?: string;
  facilityId?: string;
}

export class CommonSearchRequest {
  companyId: string;
  siteId?: string;
  departmentId?: string;
  facilityId?: string;
}

export class BaseFilter {
  status: string[];
  dateFilter: DateRange;
  page: number;
  rows: number;
  query: string;
  include: number[];
  exclude: number[];
  sortBy: string;
  sortIn: string;
  companyId: string;
  siteId: string;
  facilityId: string;
  brandId: string;
  date: string;
  departmentId: string;
  department: string;
  q: string;
  nameContains: string;
  limit: string;
  luggageStatus?: string[];
  divisionIds: string[];
  ownerships: string[];
  legalEntityIds: string[];
}

export class IncidentFilter extends BaseFilter {
  severities: string[];
  dateOfIncident: string;
  userId: string;
  incidentStatus: string[];
}

export class dateRangeFilter {
  companyId: string;
  siteId: string;
  interval: Interval;
  date?: DateFilter;
  brandId: string;
  groupLevel: string;
}

export class Interval {
  category: string;
  interval: string;
}

export class DateFilter {
  field: string;
  from: number;
  to: number;
}
