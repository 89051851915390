import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-universal-file-viewer',
  templateUrl: './universal-file-viewer.component.html',
  styleUrls: ['./universal-file-viewer.component.scss'],
})
export class UniversalFileViewerComponent implements OnInit {
  @Input() url: any;
  @Input() fileName: string;
  @Input() title: string;
  @Input() canDownload: boolean = false;
  extension: string;
  @Input() multiUrls : string[];
  @Input() baseUrl : string;
  paused = true;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  public currentIndex = 0;
  constructor(public modal: NgbActiveModal, private httpClient: HttpClient) {
    this.extension = this.getFileExtension(this.fileName);
  }

  ngOnInit() {

  }
  close() {
    this.modal.dismiss();
  }

  onSlideChange(event): void {
    this.currentIndex = event.current;
  }

  isVideo(extension) {
    if (
      extension == 'mp4' ||
      extension == 'MOV' ||
      extension == 'MKV' ||
      extension == 'AVI' ||
      extension == 'webm'
    ) {
      return true;
    } else {
      return false;
    }
  }

  isImage(extension) {
    if (
      extension == 'jpeg' ||
      extension == 'jpg' ||
      extension == 'png' ||
      extension == 'svg' ||
      extension == 'gif' ||
      extension == 'webp'
    ) {
      return true;
    } else {
      return false;
    }
  }

  getFileExtension(fileName: string): string {
    if (fileName != null) {
      const parts = fileName.split('.');
      const extension = parts[parts.length - 1];
      return extension;
    }
    return null;
  }

  downloadAttachments() {
    if(this.multiUrls?.length == 0 || !this.multiUrls){
      this.httpClient.get(this.url, { responseType: 'blob' }).subscribe((res) => {
        const fileName = this.fileName;
        const downloadUrl = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank'; // Open in a new tab
        link.setAttribute('download', fileName); // Optional: Set the download attribute for fallback
        link.click();
      });
    }
    else{
      const currentImageUrl = this.baseUrl + this.multiUrls[this.currentIndex ? this.currentIndex : 0];
      const link = document.createElement('a');
      link.href = currentImageUrl;
      link.download = `image-${this.currentIndex + 1}`; // Default filename
      link.click();
    }
    
  }
}
