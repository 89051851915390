import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AvatarModule } from 'ngx-avatar';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DaterangeFilterComponent } from './components/daterange-filter/daterange-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { SmsTemplateComponent } from './components/sms-template/sms-template.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { CommonTemplateSelectComponent } from './components/common-template-select/common-template-select.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConformationScreenComponent } from './components/conformation-screen/conformation-screen.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { UserPopoverComponent } from './components/user-popover/user-popover.component';
import { WarningConformationComponent } from './components/warning-conformation/warning-conformation.component';
import { DueDatePipe } from './pipes/due-date.pipe';
import { AvatarStackComponent } from './components/avatar-stack/avatar-stack.component';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { StatusBadgeComponent } from './components/status-badge/status-badge.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { CustomTooltipComponent } from './components/custom-tooltip/custom-tooltip.component';
import { UserDropdownComponent } from './components/user-dropdown/user-dropdown.component';
import { ErrorTemplateComponent } from './components/error-template/error-template.component';
import { UserInfoComponent } from './components/user-info/user-info.component';
import { LocationInfoComponent } from './components/location-info/location-info.component';
import { PropertyDropdownComponent } from './components/property-dropdown/property-dropdown.component';
import { UniversalFileViewerComponent } from './components/universal-file-viewer/universal-file-viewer.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { FileThumbnailComponent } from './components/file-thumbnail/file-thumbnail.component';
import { UserPropertyDropdownComponent } from './components/user-property-dropdown/user-property-dropdown.component';
import { UserPropertyFilterDropdownComponent } from './components/user-property-filter-dropdown/user-property-filter-dropdown.component';
import { PercentageIndicatorComponent } from './components/percentage-indicator/percentage-indicator.component';
import { SparklineChartComponent } from './components/sparkline-chart/sparkline-chart.component';
import { CommonFilterComponent } from './components/common-filter/common-filter.component';
import { AvatarListComponent } from './components/avatar-list/avatar-list.component';
import { DepartmentInfoComponent } from './components/department-info/department-info.component';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { TeamInfoComponent } from './components/team-info/team-info.component';
import { GenerateInspectionUrlComponent } from './components/generate-inspection-url/generate-inspection-url.component';
import { SampleComponent } from './components/sample/sample.component';
import { InspectionComponent } from '../../app/shared/components/inspection/inspection.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ImageUploadDrawComponent } from './components/image-upload-draw/image-upload-draw.component';
import { WebcamModule } from 'ngx-webcam';
import { GuestInfoComponent } from './components/guest-info/guest-info.component';
import { CommonFileUploadComponent } from './components/common-file-upload/common-file-upload.component';
import { DebounceSearchInputComponent } from './components/debounce-search-input/debounce-search-input.component';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    PaginationComponent,
    FileUploadComponent,
    DaterangeFilterComponent,
    TimeAgoPipe,
    SmsTemplateComponent,
    EmailTemplateComponent,
    CommonTemplateSelectComponent,
    ConformationScreenComponent,
    UserPopoverComponent,
    WarningConformationComponent,
    DueDatePipe,
    AvatarStackComponent,
    AvatarListComponent,
    StatusBadgeComponent,
    CustomTooltipComponent,
    UserDropdownComponent,
    ErrorTemplateComponent,
    UserInfoComponent,
    LocationInfoComponent,
    PropertyDropdownComponent,
    UniversalFileViewerComponent,
    FileThumbnailComponent,
    UserPropertyDropdownComponent,
    UserPropertyFilterDropdownComponent,
    PercentageIndicatorComponent,
    SparklineChartComponent,
    CommonFilterComponent,
    DepartmentInfoComponent,
    CommonTableComponent,
    TeamInfoComponent,
    GenerateInspectionUrlComponent,
    InspectionComponent,
    ImageUploadDrawComponent,
    GuestInfoComponent,
    CommonFileUploadComponent,
    DebounceSearchInputComponent,
  ],
  imports: [
    CommonModule,
    AvatarModule,
    NgbModalModule,
    NgbModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    CKEditorModule,
    NgSelectModule,
    MatTooltipModule,
    PdfViewerModule,
    QRCodeModule,
    WebcamModule,
    NgbCarouselModule
  ],
  exports: [
    PaginationComponent,
    AvatarModule,
    FileUploadComponent,
    DaterangeFilterComponent,
    EmailTemplateComponent,
    SmsTemplateComponent,
    CommonTemplateSelectComponent,
    TimeAgoPipe,
    ConformationScreenComponent,
    UserPopoverComponent,
    WarningConformationComponent,
    PropertyDropdownComponent,
    DueDatePipe,
    AvatarStackComponent,
    UserDropdownComponent,
    ErrorTemplateComponent,
    UserInfoComponent,
    LocationInfoComponent,
    ConformationScreenComponent,
    UserPopoverComponent,
    WarningConformationComponent,
    DueDatePipe,
    AvatarStackComponent,
    AvatarListComponent,
    UserDropdownComponent,
    ErrorTemplateComponent,
    UserInfoComponent,
    LocationInfoComponent,
    UniversalFileViewerComponent,
    FileThumbnailComponent,
    UserPropertyDropdownComponent,
    PercentageIndicatorComponent,
    CommonFilterComponent,
    UserPropertyFilterDropdownComponent,
    PercentageIndicatorComponent,
    DepartmentInfoComponent,
    TeamInfoComponent,
    GenerateInspectionUrlComponent,
    InspectionComponent,
    ImageUploadDrawComponent,
    CommonTableComponent,
    GenerateInspectionUrlComponent,
    GuestInfoComponent,
    CommonFileUploadComponent,
    DebounceSearchInputComponent,
  ],
})
export class SharedModule {}
