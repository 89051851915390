import { CommonRequest, Role } from 'src/app/core/models/common.models';
import { UserPermissions } from './permission.models';
import {
  Department,
  DepartmentName,
} from '../../department/models/department.models';
import { UserEntity } from '../../auth/models/auth.models';

export class User {
  public status?: string;
  public addedBy?: string;
  public addedOn?: number;
  public lastUpdated?: number;
  public registerAccount?: boolean;
  public userProfile?: UserProfile;
  public designation?: string;
  public role?: Role | string;
  public userType?: string;
  public permission?: UserPermissions;
  public phone?: Phone;
  public email?: UserEmail;
  public type?: string;
  public device?: Device;
  public userName?: string;
  public siteIds?: string[] = [];
  public divisionId: string;
  public companyId?: string;
  public departmentId?: string;
  public department?: DepartmentName;
  public departmentInfo?: Department;
  public position: string;
  public positionName: string;
  public departmentName: any;
  public label?: string;
  public siteId?: string;
  public facilityId?: string;
  public facilityIds?: string[] = [];
  public id?: string;
  public _id?: string;
  public displayName?: string;
  public firstName?: string;
  public lastName?: string;
  public userImage?: string;
  public userEntity: UserEntity;
  public userEntities: UserEntity[];
  public language: string;
  public shortName: string;
  public teamIds: string[];
}

export class ListUserRequest {
  public siteId?: string;
  public facilityId?: string;
  public companyId?: string;
  public status?: Status;
}

export class Phone {
  public countryCode?: string;
  public phone?: string;
  public phoneNumber: string;
  public phoneNumberType?: string;
  public regionCode?: string;
  public smsNotSupported?: boolean;
  public valid?: boolean;
}

export class UserProfile {
  public displayName?: string;
  public firstName?: string;
  public lastName?: string;
  public userImage?: string;
  public address?: Address;
}

export class Address {
  addressLine1?: string;
  addressLine2?: string;
  state?: string;
  city?: string;
  country?: string;
}

export class UserSearchRequest {
  public currentPage?: number = 1;
  public emailId?: string;
  public nameContains?: string;
  public rowsPerPage?: number = 10;
  public status?: Status[];
  public roles?: Role[];
  public siteIds?: string[];
  public companyId?: string;
  public department: string;
}

export class UserSearchResponseList {
  public userList?: User[];
  public recordsPerPage?: number;
  public totalPages?: number;
  public totalRecords?: number;
}

export class UserEmail {
  public emailId?: string;
  public valid?: boolean;
}

export class Device {
  enabled: boolean = false;
  session: any;
  templates: DeviceTemplate[] = [];
}

export class DeviceTemplate {
  addedOn: number;
  id: string;
  identifier: string;
  lastSessionAt: number;
  mode: number;
  name: string;
  status: string;
}

export class DepartmentDropdownRequest {
  public companyId: string;
  public siteId?: string;
}

export class DepartmentDropdownResponse {
  name: string;
  companyId: string;
  status: string;
  addedOn: number;
  lastUpdated: number;
  _id: string;
  displayName: string;
}

export class DepartmentAccoridanList extends DepartmentDropdownResponse {
  areaExpanded: boolean = false;
}

export enum Status {
  ACTIVE = 'ENABLED',
  DISABLED = 'DISABLED',
  DELETED = 'DELETED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
}

export enum UserType {
  STANDARD = 'STANDARD',
  CONTACT = 'CONTACT',
}

export class ResetPasswordRequest {
  userId: string;
  password: string;
}

// team models
export class Team extends CommonRequest {
  name: string;
  description: string;
  members: any[];
  memberInfo: User[];
  leader: string;
  id?: string;
  addedOn: number;
  updatedOn: number;
  addedBy: string;
  addedInfo: User;
  count: number;
  propertyInfo: UserEntity;
  status: string;
}
export class TeamResponse {
  status: string;
  addedOn: number;
  lastUpdated: number;
  name: string;
  description: string;
  leader: string;
  members: string[];
  companyId: string;
  id: string;
}

export class TeamList extends CommonRequest {
  name: string;
  description: string;
  leader: string;
  members: string[];
  id: string;
  addedOn: number;
  count: number;
  propertyInfo: UserEntity;
}

export class UserRole {
  role: string;
  department: string;
  value: string;
}


export class UserPositions {
  position: string
  positionName: string
}