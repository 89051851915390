import { map } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionConstants } from 'src/app/core/constants/session.constants';
import { BaseService } from 'src/app/core/services/base.service';
import { CommonService } from 'src/app/core/services/common.service';
import {
  LogoutRequest,
  SwitchProfileRequest,
} from 'src/app/modules/auth/models/auth.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { User } from 'src/app/modules/user/models/user.models';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import StringUtils from 'src/app/shared/Utils/stringUtils';
import { PermissionEvaluator } from 'src/app/core/permissions/permission.controller';
import { BaseFilter, Role } from 'src/app/core/models/common.models';
import { ToastrService } from 'ngx-toastr';
import {
  BaseReq,
  UserService,
} from 'src/app/modules/user/services/user.service';
import { SessionUtils } from 'src/app/shared/Utils/sessionUtils';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent
  extends BaseService
  implements OnInit, OnDestroy
{
  public currentUser: User;
  public userImage: string;
  public displayName: string;
  public displayText: string;
  public designation: string;
  public webView: boolean = true;
  public navSelected: boolean = false;
  permissionEvaluator = PermissionEvaluator.getInstance();
  public showProfileDrop: boolean = false;
  public sitesList = [];
  public companyUser: boolean = false;
  constructor(
    private commonService: CommonService,
    public authService: AuthService,
    private router: Router,
    private activeRout: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private tostr: ToastrService,
    private userService: UserService
  ) {
    super();
  }
  ngOnDestroy(): void {
    this.permissionEvaluator.destroy();
  }
  ngOnInit(): void {
    this.init();
    this.breakpointObserver
      .observe('(max-width: 991px)')
      .subscribe((result: BreakpointState) => {
        this.webView = !result.matches;
      });

    // this.authService.getCurrentUserObservable().subscribe({
    //   next:(response)=>{
    //   },
    //   error:()=>{}
    // })

    if (this.authService.isCompanyUser()) {
      this.companyUser = true;
    } else {
      this.companyUser = false;
    }
  }

  openExternalLink() {
    const url = `${this.oldSiteURL}/#/log-management/logs`;
    window.open(url, '_blank');
  }

  canSwitchProfile() {
    return this.authService.isRoleIn([Role.PROPERTY_USER, Role.FACILITY_USER]);
  }

  getActiveSite() {
    let activeSiteId = this.authService.getCurrentActiveProfileId();
    return this.getProfileList()?.find(
      (profile) => profile.id === activeSiteId
    );
  }

  init() {
    this.currentUser = this.authService.getCurrentUser();
    let userId = this.authService.getCurrentUserId();
    this.userService.getUserInfoCached(userId).subscribe({
      next: (response) => {
        if (this.currentUser && this.currentUser.userProfile) {
          if (this.currentUser.userProfile['userImage']) {
            this.userImage =
              this.imgURL +
              'api/instio/images/' +
              this.currentUser.userProfile['userImage'];
          }
          this.displayName = this.currentUser.userProfile.displayName;
          this.designation = response.positionName;
        }
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  hideNav() {
    this.commonService.showSideBar = !this.commonService.showSideBar;
  }

  logout() {
    const payload = new LogoutRequest();
    this.authService.logout(payload).subscribe({
      next: (response) => {
        localStorage.clear();
        sessionStorage.clear();
        // localStorage.removeItem(SessionConstants.SESSION);
        // localStorage.removeItem(SessionConstants.CALLBACK_SESSION);
        // localStorage.removeItem(SessionConstants.CURRENT_USER);
        // localStorage.removeItem(SessionConstants.CALLBACK_CURRENT_USER);
        // localStorage.removeItem(SessionConstants.ENTITY_COMPANY);
        // localStorage.removeItem(SessionConstants.CALLBACK_ENTITY_COMPANY);
        // localStorage.removeItem(SessionConstants.ENTITY_PROFILE);
        // localStorage.removeItem(SessionConstants.CALLBACK_ENTITY_PROFILE);
        // localStorage.removeItem(SessionConstants.CHAT_SELECTED);
        // localStorage.removeItem(SessionConstants.CALLBACK_CHAT_SELECTED);
        // localStorage.removeItem(SessionConstants.USERS_LIST);
        // localStorage.removeItem(SessionConstants.CALLBACK_USERS_LIST);
        this.router.navigate(['/login']);
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
  closeNav() {
    if (!this.webView) this.navSelected = false;
  }

  getDisplayString(name: string) {
    return StringUtils.getDisplayNameString(name);
  }
  getProfileListForUser() {
    let session = this.authService.getSession();
    let availableProfiles = session.siteIds;
    let activeSiteId = this.authService.getCurrentActiveProfileId();
    return this.getProfileList()?.filter(
      (profile) =>
        availableProfiles.includes(profile.id) && profile.id != activeSiteId
    );
  }

  getProfileList() {
    let currentUser = this.authService.getCurrentUser();
    if (currentUser.role === Role.PROPERTY_USER) {
      return this.authService.getUserEntity();
    }
    if (currentUser.role === Role.FACILITY_USER) {
      return this.authService.getUserEntity();
    }
    return [];
  }

  switchProfile(siteId: string) {
    if (siteId != this.getActiveSite()._id) {
      let session = this.authService.getSession();
      let payload = new SwitchProfileRequest();
      payload.sessionId = session.sessionId;
      payload.profileId = siteId;
      payload.token = session.userToken;
      payload.userId = session.userId;
      this.authService.switchProfile(payload).subscribe({
        next: (res) => {
          this.tostr.success('Profile Switched successfully');
          this.authService.setSession(res.data);
          location.reload();
        },
        error: (err) => {
          console.log(err);
        },
      });
    }
  }
  isV3User() {
    return this.authService.getSession()?.additionalInfo?.version == 'V3';
  }

  isCollaborationActive(): boolean{
    const paths = ['/announcements/', '/scheduler/my-calendar', 'task', 'meetings', '/dashboard/guest'];
    const currentRoute = this.router.url;
    return paths.some((path) => currentRoute.includes(path));
  }

  isGuestManagementActive(): boolean {
    const paths = [
      '/gms/room-calender',
      '/crm/guest',
      '/loyalty',
      '/segments',
      '/campaign',
    ];
    const currentRoute = this.router.url;
    return paths.some((path) => currentRoute.includes(path));
  }

  isOperationsActive(): boolean {
    const paths = [
      '/gate-pass',
      '/work-order',
      'housekeeping',
      '/log-management',
      'goals/kra-home',
      'lost-and-found',
    ];
    const unWanted = '/log-management/incidents';
    const currentRoute = this.router.url;
    return paths.some(
      (path) => currentRoute.includes(path) && !currentRoute.includes(unWanted)
    );
  }

  isHKReportsActive(): boolean {
    const paths = [
      '/housekeeping/status-report-attendance',
      '/housekeeping/status-report-room-status',
      '/housekeeping/room-report',
      '/housekeeping/performance-board',
      '/housekeeping/guest-ra-rquest',
      '/housekeeping/room-status-reports',
      '/housekeeping/status-report-daily-status',
    ];
    return paths.some((path) => this.router.url.includes(path));
  }

  isHKSettingsActive(): boolean {
    const paths = [
      '/cleaning-preferences',
      '/cleaning-profiles',
      '/assignment-rule',
    ];
    return paths.some((path) => this.router.url.includes(path));
  }

  isQualityActive(): boolean {
    const paths = ['/log-management/incidents', '/audit', '/checklist/', '/regulatory-compliances'];
    return paths.some((path) => this.router.url.includes(path));
  }

  isSettingsActive(): boolean {
    const paths = ['/templates', 'user-management', 'configuration-settings'];
    const unWanted = 'checklist/templates';
    const currentRoute = this.router.url;
    // return paths.some(path => this.router.url.includes(path));
    return paths.some(
      (path) => currentRoute.includes(path) && !currentRoute.includes(unWanted)
    );
  }
}
